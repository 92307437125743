@font-face {
    font-family: "db_heaventbold";
    src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot");
    src: url("./assets/fonts/db_heavent_bd_v3.2-webfont.eot?#iefix")
        format("embedded-opentype"),
      url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff2") format("woff2"),
      url("./assets/fonts/db_heavent_bd_v3.2-webfont.woff") format("woff"),
      url("./assets/fonts/db_heavent_bd_v3.2-webfont.ttf") format("truetype"),
      url("./assets/fonts/db_heavent_bd_v3.2-webfont.svg#db_heaventbold")
        format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
font-family: "db_heaventmed";
src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot");
src: url("./assets/fonts/db_heavent_med_v3.2-webfont.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_med_v3.2-webfont.svg#db_heaventmed")
    format("svg");
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: "db_heaventthin";
src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot");
src: url("./assets/fonts/db_heavent_thin_v3.2-webfont.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_thin_v3.2-webfont.svg#db_heaventthin")
    format("svg");
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: "db_heavent";
src: url("./assets/fonts/db_heavent_v3.2-webfont.eot");
src: url("./assets/fonts/db_heavent_v3.2-webfont.eot?#iefix")
    format("embedded-opentype"),
    url("./assets/fonts/db_heavent_v3.2-webfont.woff2") format("woff2"),
    url("./assets/fonts/db_heavent_v3.2-webfont.woff") format("woff"),
    url("./assets/fonts/db_heavent_v3.2-webfont.ttf") format("truetype"),
    url("./assets/fonts/db_heavent_v3.2-webfont.svg#db_heaventthin")
    format("svg");
font-weight: normal;
font-style: normal;
}

$fill-green: #249627;
$fill-red: #d80012;
$fill-blue: #2b6fb6;
$fill-blue-font: #017aff;
$gradient-bluesky-top: #007dfe;
$gradient-bluesky-bottom: #00a3fe;
$fill-grey-font: #80879a;
$fill-grey-font-light: #ccc;
$fill-grey-line: #e4e4e4;
$fill-grey-light: #f9f9f9;
$gradient-grey-top: #646567;
$gradient-grey-bottom: #797a7c;
$fill-black: #000;
$fill-white: #fff;
$fill-main-bg: #FAFBFD;

$shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
}

hr {
    border: 1px solid $fill-grey-line;
}

h1 {
    font-size: 2.6rem;  
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    font-family: "db_heaventmed";
}

p {
  font-weight: normal;
  font-family: "db_heavent";
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 0.6;
}

ul {
    list-style: none;
}

button {
    border: none;
    margin: 0 10px;
    background-color: transparent;
    font-size: 1.4rem;
    width: 100%;

    &:focus {
        outline: none !important;
        outline-offset: none !important;
    }
}

.btn-ellipse {
    border-radius: 30px;
    padding: 15px 20px;
    min-width: 140px;
    margin: 0;
    box-shadow: $shadow;

    &._grey {
        margin-right: 5px;
        background: rgb(99, 100, 102);
        background: -moz-linear-gradient(
            180deg,
            rgba(99, 100, 102, 1) 0%,
            rgba(122, 123, 123, 1) 100%
        );
        background: -webkit-linear-gradient(
            180deg,
            rgba(99, 100, 102, 1) 0%,
            rgba(122, 123, 123, 1) 100%
        );
        background: linear-gradient(
            180deg,
            rgba(99, 100, 102, 1) 0%,
            rgba(122, 123, 123, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#666769",endColorstr="#7a7b7c",GradientType=1);
    }

    &._blue {
        margin-left: 5px;
        background: rgb(3, 125, 255);
        background: -moz-linear-gradient(
            180deg,
            rgba(3, 125, 255, 1) 0%,
            rgba(0, 163, 254, 1) 100%
        );
        background: -webkit-linear-gradient(
            180deg,
            rgba(3, 125, 255, 1) 0%,
            rgba(0, 163, 254, 1) 100%
        );
        background: linear-gradient(
            180deg,
            rgba(3, 125, 255, 1) 0%,
            rgba(0, 163, 254, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#007dfe",endColorstr="#00a3fe",GradientType=1);
    }

    p {
        font-size: 1.6rem !important;
    }
}
  
  
.btn-capsule {
    background-color: $fill-white;
    width: auto;
    height: auto;
    padding: 8px 24px;
    margin: auto;
    border-radius: 15px;
}
  
.btn-round {
    // min-width: 180px;
    background-color: $fill-white;
    box-shadow: $shadow;
    // width: auto;
    height: auto;
    padding: 16px 16%;
    margin: auto;
    border-radius: 8px;
}
  
.btn-square {
    max-width: 110px;

    .btn-image {
    border-radius: 15px;
    background-color: $fill-white;
    box-shadow: $shadow;
    width: 84px;
    height: 84px;
    padding: 24px;
    margin: auto;

    img {
        width: 36px;
        height: 36px;
    }
    }

    p {
    margin-top: 10px;
    font-size: 1.5rem;
    line-height: 0.8;
    }
}
  
.btn-row {
    width: 100%;
    display: flex;
    align-items: center;

    &._bottom {
      padding-top: 10px;
      text-align: center;
      position: fixed;
      bottom: 0;
      left: 0;
      margin: auto;
      height: 15%;
      background-color: #fff;
      background: -moz-linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
      );
      background: -webkit-linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
      );
      background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="transparent",endColorstr="#ffffff",GradientType=1);
      
      &._center {
        left: 0;
        right: 0;
        margin: auto;
        justify-content: center;
      }
    }
}

.btn-fluid {
    button {
    width: 100%;
    margin-bottom: 10px;
    }
}

.btn-ellipse {
  &._3d {
    box-shadow: 0 5px 0 #2989f5;
    width: 50%;
  }
}

.bg-gradient-blue {
    background: rgb(3, 125, 255);
    background: -moz-linear-gradient(
    180deg,
    rgba(3, 125, 255, 1) 0%,
    rgba(0, 163, 254, 1) 100%
    );
    background: -webkit-linear-gradient(
    180deg,
    rgba(3, 125, 255, 1) 0%,
    rgba(0, 163, 254, 1) 100%
    );
    background: linear-gradient(
    180deg,
    rgba(3, 125, 255, 1) 0%,
    rgba(0, 163, 254, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#007dfe",endColorstr="#00a3fe",GradientType=1);
}

.bg-gradient-white {
    border: 1px solid $fill-blue-font;

    > h3 {
    color: $fill-blue-font;
    }
}

.bg-gradient-whitegrey {
    border: 1px solid $gradient-grey-top;

    > h3 {
    color: $gradient-grey-top;
    }
}


.bg-gradient-grey {
    background: rgb(102, 102, 102);
    background: -moz-linear-gradient(
    180deg,
    rgba(102, 102, 102, 1) 0%,
    rgba(140, 140, 140, 1) 100%
    );
    background: -webkit-linear-gradient(
    180deg,
    rgba(102, 102, 102, 1) 0%,
    rgba(140, 140, 140, 1) 100%
    );
    background: linear-gradient(
    180deg,
    rgba(102, 102, 102, 1) 0%,
    rgba(140, 140, 140, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#666666",endColorstr="#8a8a8a",GradientType=1);
}

.bg-transparent {
    background-color: transparent;
    border: 1px solid #fff;
}

.input-text {
    padding: 8px 8px;
    display: inline-block;
    font-size: 1.6rem;
    line-height: 0.8;
    font-family: "db_heavent";
    text-align: left;
    width: 100%;
    border: 1px solid $fill-grey-line;
    border-radius: 8px;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $fill-grey-font-light;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $fill-grey-font-light;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $fill-grey-font-light;
    }

    &.font-red {
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $fill-red;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $fill-red;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $fill-red;
      }
    }

}

.font-blue {
    color: $fill-blue-font;
}

.font-blue-dark {
    color: $fill-blue;
}

.font-black {
    color: $fill-black;
}

.font-red {
    color: $fill-red;
}

.font-green {
    color: $fill-green;
}

.font-grey {
    color: $fill-grey-font;
}

.font-white {
    color: $fill-white;
}

.font-bold {
    font-weight: bold;
}

.txt-center {
  text-align: center;
}

.txt-left {
    text-align: left;
}

.txt-right {
    text-align: right;
}
  

// Core --------------------------------------------//
body {
  background-color: $fill-white;
  color: $fill-black;
  font-size: 1.4rem;
  font-family: "db_heaventmed", sans-serif;
}

body,
html {
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  background-color: $fill-main-bg;
}

// #root {

//   .btn-back {
//     position: absolute;
//     top: 18px;
//     left: 18px;
//     width: 24px;
//     height: 24px;
//     img {
//       width: 100%;
//     }
//   }
// }

.loading {
  &.container {
    .container-top {
      height: 100vh;

      h3 {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100px;
        height: 80px;
      }
    }
  }
}

.lazy-loading {
  width: 40px;
  height: 40px;
  margin: 2% auto;
  // position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  img {
    width: 100%;
  }
}

.container {
    padding: 0 8%;
  
    .landing {
        width: 100vw;
        height: 88vh;
    
        .landing-box {
            text-align: center;
            width: 200px;
            height: 240px;
            position: absolute;
            top: -18%;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
    
            h1,
            h3 {
            margin-bottom: 10px;
            }
        }
        }
    
        .container-top {
        padding: 30px 20px;
        text-align: center;
        background: rgb(3, 125, 255);
        background: -moz-linear-gradient(
            180deg,
            rgba(3, 125, 255, 1) 0%,
            rgba(0, 163, 254, 1) 100%
        );
        background: -webkit-linear-gradient(
            180deg,
            rgba(3, 125, 255, 1) 0%,
            rgba(0, 163, 254, 1) 100%
        );
        background: linear-gradient(
            180deg,
            rgba(3, 125, 255, 1) 0%,
            rgba(0, 163, 254, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#007dfe",endColorstr="#00a3fe",GradientType=1);
    
        h3 {
            color: $fill-white;
            margin-bottom: 30px;
            font-size: 2rem;
        }
    
        .btn-back {
            position: absolute;
            left: 20px;
            top: 20px;
        }
    }
}


// Box -----------------------------------------------------//
.box-style {
    background-color: $fill-white;
    border-radius: 20px;
    border: 1px solid #eef2f5;
    margin-bottom: 30px;
    box-shadow: $shadow;
}

// Coupon Card --------------------------------------------//
.coupon-list-box {
  .couponGroup {
    margin: 20px 0 10px;
    color: #646464;
    font-size: 1.4rem;
    .couponGroupDate {
      font-family: 'db_heavent';
      margin-top: 10px;
    }
  }
}

.couponcard {
  padding: 4% 30px 2%;
  border: 1px solid $fill-grey-line;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  margin: 10px 0;
  border-radius: 10px;
  position: relative;
  img {
    width: 20px;
    margin-right: 10px;
    height: 20px;
  }
  h3 {
    font-size: 1.8rem;
  }
  p {
    color: #646464;
  }
  &::before {
    content: '';
    position: absolute;
    width: 14%;
    height: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    left: -4px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-position: 0;
    background-image: url(./assets/img/ic-border-l.png);
  }
  &::after {
    content: '';
    position: absolute;
    width: 14%;
    height: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    right: -4px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-position: 100%;
    background-image: url(./assets/img/ic-border-r.png);
  }

  .shopname {
    display: flex;
    align-items: center;
    > * {
      display: flex;
      align-items: center;
    }
    .col-l {
      width: 60%;
    }
    .col-r {
      width: 50%;
      text-align: right;
      align-self: flex-end;
    }
    p {
      width: 100%;
      text-align: right;
      span {
        font-size: 1.8rem;
        color: $fill-blue-font;
        font-family: 'db_heaventmed';
      }
    }
  }
  .total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
    span {
      font-size: 1.8rem;
      color: $fill-blue-font;
      font-family: 'db_heaventmed';
    }
  }
  .detail {
    display: flex;   
    border-top: 1px solid $fill-grey-line; 
    padding: 2% 0;
    span {
      color: $fill-grey-line;
      margin: 0 2%;
    }
  }
}

// Modal ----------------------------------------------------------//
._modal {
    width: 90%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 50% auto;
    transform: translate(0, 15%);
  
    ._error-modal,
    ._insertcode-modal {
      text-align: center;
      padding: 20px;
  
      p {
        color: $fill-black;
        font-size: 1.6rem;
        &.font-red {
          font-size: 1.2rem;
          color: $fill-red;
          text-align: left;
        }
      }
  
      input {
        border: 1px solid $fill-blue-font;
        color: $fill-blue-font;
        font-family: "db_heaventmed";
        border-radius: 10px 10px 10px 10px;
        font-size: 2rem;
        padding: 6px 6px;
        margin: 10px 0 20px;
        width: 100%;
      }
  
      button {
        p {
          color: $fill-white;
        }
      }
    }
  
    ._result {
      text-align: center;
      padding: 20px;
      font-size: 2rem;
  
      > div {
        margin: 10px 0 12px;
      }
  
      img {
        vertical-align: bottom;
        margin-right: 10px;
      }
  
      span,
      p {
        font-size: 1.6rem;
        margin-right: 8px;
        line-height: 1;
  
        &:last-child {
          font-size: 2rem;
          margin: 0;
        }
  
        &.font-blue {
          font-size: 2.6rem;
        }
      }
  
      button {
        margin-top: 18px;
        width: 180px;
  
        .btn-ellipse {
          p {
            color: $fill-white;
            font-size: 1.6rem;
          }
        }
      }
    }
}

// Coupon Modal --------------------------------------------------//
.modal-selectcoupon {
  transform: translate(0, -30%);
}

.modal-content {
  padding: 20px;
  b {
    font-family: "db_heaventbold";
  }

  > h3 {
    color: #000;
    line-height: 1;
    margin-bottom: 10px;
  }

  > div > h3 {
    color: #000;
    line-height: 1;
    margin-bottom: -10px;
  }

  .modal-coupon-list {
    text-align: center;
    margin: 5px 0 15px;
    max-height: 120px;
    overflow: auto;

    li {
      margin-bottom: 10px;
    }

    h3 {
      color: $fill-blue-font;
    }
    p {
      font-size: 1rem;
      color: $fill-grey-font;
    }
  }

  .modal-insertcode {
    input {
      border: 1px solid $fill-blue-font;
      color: $fill-blue-font;
    }
  }
}

// Page ----------------------------------------------------//

.login-page {
    > h3 {
        font-size: 2.6rem;
        font-family: "db_heaventbold";
        margin-top: 20%;
        line-height: 1;
    }
    p {
        font-family: "db_heavent";
        line-height: 1.6;
        margin-bottom: 15%;
        color: $fill-grey-font;
    }
    input {
        margin: 10px 0;

        &[type=checkbox] {
            margin-right: 10px;
        }
    }
    label {
        font-family: "db_heavent";
    }
    button {
        margin-top: 15%;
    }
    .txt-error {
        font-family: "db_heavent";
        color: $fill-red;
        display: inline-block;
        width: 100%;
    }
}

.setpassword-page, .scan-page {
    h2 {
        font-size: 2rem;
        font-family: "db_heaventbold";
        margin-top: 20%;
        line-height: 1;
        margin-bottom: 15%;
    }
    button {
        margin-bottom: 10px;
    }
    input[type="text"] {
      border: 1px solid #017aff;
    }
    .divider {
        margin: 10% 0;
        border-top: 1px solid $fill-grey-line;
        p {
            background-color: $fill-main-bg;
            width: 40px;
            margin: -14px auto;
        }
    }
    .password-scan {
      width: 100%;
      height: 100%;
      background-color: $fill-black;
    }
}

.modal-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(241, 245, 246,0.8);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Home page ------------------------------------------------------//
.home-page {
  .header {
    padding: 20px 20px 30px;
    background: rgb(3, 125, 255);
    background: -moz-linear-gradient(
    180deg,
    rgba(3, 125, 255, 1) 0%,
    rgba(0, 163, 254, 1) 100%
    );
    background: -webkit-linear-gradient(
    180deg,
    rgba(3, 125, 255, 1) 0%,
    rgba(0, 163, 254, 1) 100%
    );
    background: linear-gradient(
    180deg,
    rgba(3, 125, 255, 1) 0%,
    rgba(0, 163, 254, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#007dfe",endColorstr="#00a3fe",GradientType=1);

    .profile {
      display: flex;
      margin-bottom: 20px;
      img {
        width: 8%;
        margin-right: 4%;
      }
      h3 {
        display: inline;
        line-height: .6;
      }
      .down {
        p {
          font-size: 1.2rem;
        }
      }
    }

    .status {
      display: flex;
    }

    .col-l {
      width: 72%;
      > * > * {
        display: inline-block;
      }
    }
    .col-r {
      width: 28%;
      text-align: right;
    }

    p, h3 {
      color: #fff;
      line-height: 1;
    }

    .btn-logout {
      margin: 0;
      p {
        font-size: 1.2rem;
        text-decoration: underline;
        text-align: right;
      }
    }
  }
  .content {
    padding: 0 20px 0;
    .select-period {
      box-shadow: 0 4px 6px rgba(52, 52, 52, 0.15);
      margin-top: -20px;
      border-radius: 4px;
    }
    .empty-coupon {
      margin-top: 40px;
      img {
        margin-bottom: 10px;
      }
      p {
        margin-top: 20px;
        color: #8A8D92;
      }
    }
  }

  .btn-row {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    h3 {
      margin-bottom: 0;
    }
    p {
      margin-top: 10px;
      color: #8A8D92;
    }
  }
}

// Scan page ------------------------------------------------------//
.scanqrcode-box {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .dbrScanner-bg-loading {
    animation: 1s linear infinite dbrScanner-rotate;
    width: 20%;
    height: 20%;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    fill: #aaa;
  }

  @keyframes dbrScanner-rotate {
    from {
      transform: rotate(0turn);
    }
    to {
      transform: rotate(1turn);
    }
  }

  .btn-back {
    position: absolute;
    // top: 20px;
    // left: 20px;
  }

  .qrscanner {
    position: absolute;
    height: 75%;
    margin: auto;
    left: 0;
    right: 0;
    top: 2%;
    bottom: 0;
    z-index: 1;
  }

  ._header {
    position: relative;
    width: 100%;
    z-index: 2;
    // height: 79px;
    padding: 10px;
    background-color: $fill-white;
    p {
      font-family: 'db_heavent';
      font-size: 1.2rem;
    }
  }

  ._browsefile {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
  }

  ._footer {
    position: fixed;
    bottom: 10px;
    left: 0;
    padding: 18px 20px 30px;
    width: 100%;
    z-index: 2;
    height: 74px;
  }
}

// Html5-qrcode ------------------------------------------------------//
.component-qr-scanner {
  width: 100vw;
  height: 100vh;
  // min-width: 640px;
  // min-height: 480px;
  background: #eee;
  position: relative;
  resize: both;
}

.qr-bg-loading {
  animation: 1s linear infinite dbrScanner-rotate;
  width: 20%;
  height: 20%;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  fill: #aaa;
}

.camera-reader {
  // height: 100vh - 21;
  // overflow: hidden;

  > div:first-child {
    display: none;
  }
}

#camera-reader__scan_region {
  margin-top: 79px;
  // height: 100vh - 48;
  position: relative;
  background: url(./assets/img/ic-scanqr.svg) no-repeat;
  background-size: 20%;
  background-position: center;
  img {
    opacity: 0.3;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50%;
    display: none;
  }
}

#camera-reader__dashboard {
  background-color: $fill-black;
}

// Camera section
#camera-reader__dashboard_section_csr {
  > div {
    > button {
      text-align: center;
      padding: 10px 20px;
      font-family: "db_heaventmed";
      color: $gradient-bluesky-top;
      width: 250px;
      background-color: $gradient-bluesky-top;
      display: inline-block;
      border-radius: 30px;
      position: relative;
      &:first-child {
        &::before {
          position: absolute;
          left: 0;
          right: 0;
          margin-top: auto;
          color: $fill-white;
          content: "กดเพื่อสแกน QR Code";
          font-size: 24px;
        }
      }
    }
  }
  > span:first-child {
    color: #000;
    display: inline-block;
    margin: 0 0 25px !important;
    text-align: center;
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      margin-top: auto;
      height: 20px;
      color: $fill-white;
      content: "กรุณาเลือกกล้อง";
      font-size: 24px;
    }
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      margin-top: auto;
      height: 20px;
      color: $fill-white;
      content: "facing front = กล้องหน้า, facing back = กล้องหลัง";
      font-size: 18px;
    }
  }
  > span:last-child {
    > button {
      text-align: center;
      padding: 10px 20px;
      font-family: "db_heaventmed";
      color: $gradient-bluesky-top;
      width: 250px;
      background-color: $gradient-bluesky-top;
      display: inline-block;
      border-radius: 30px;
      position: relative;
      &:first-child {
        &::before {
          position: absolute;
          left: 0;
          right: 0;
          margin-top: auto;
          color: $fill-white;
          content: "สแกน QR Code";
          font-size: 24px;
        }
      }
      &:last-child {
        &::before {
          position: absolute;
          left: 0;
          right: 0;
          margin-top: auto;
          color: $fill-white;
          content: "ยกเลิกสแกน QR Code";
          font-size: 24px;
        }
      }
    }
  }
  #camera-reader__camera_selection {
    padding: 10px;
    display: block;
  }
}

// Image section
#camera-reader__dashboard_section_fsr {
  width: 250px;
  padding: 10px;
  background: #fff;
  border-radius: 30px;
  display: inline-block;
  margin: auto;
  &::before {
    position: absolute;
    left: 0;
    right: 0;
    margin-top: auto;
    color: $fill-black;
    content: "เลือกรูปภาพ";
    font-size: 24px;
  }
  #camera-reader__filescan_input {
    opacity: 0;
  }
  span {
    display: none;
  }
}

#camera-reader__dashboard_section_swaplink {
  margin-top: 10px;
  text-align: center;
  padding: 10px 20px;
  font-family: "db_heaventmed";
  color: $gradient-grey-top;
  width: 250px;
  background-color: $gradient-grey-top;
  display: inline-block;
  border-radius: 30px;
  position: relative;
  &:first-child {
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      margin-top: auto;
      color: $fill-white;
      content: "อัพโหลดรูป QR Code";
      font-size: 24px;
    }
  }
}

// Responsive -----------------------------------------------------//
// @media (min-width: 415px) {
//   .couponcard {
//     border-radius: 33px 33px 33px 33px;
//     &._selected {
//       border-radius: 33px 33px 33px 33px;
//     }
//   }
// }

@media (max-width: 413px) {
  // .couponcard {
  //   min-height: 128px;
  // }
  .btn-row {
    &._bottom {
      transform: translateY(6px);
    }
  }
}

@media (max-width: 374px) {
  // .couponcard {
  //   min-height: 128px;
  // }
  .btn-row {
    &._bottom {
      transform: translateY(16px);
    }
  }
}

@media (max-width: 320px) {
  h1 {
    font-size: 2.4rem;
  }

  button {
    margin: 0 5px;
  }

  .btn-ellipse {
    min-width: 120px;
    &._3d {
      width: 80%;
    }
  }

  .action-box-bottom {
    .btn-ellipse {
      min-width: 110px;
    }
  }

  .coupon-list-box {
    padding-bottom: 40px;
  }

  .empty-coupon {
    img {
      width: 20%;
      margin-bottom: 10px;
    }
    p {
      font-size: 1.2rem;
    }
  }

  .couponcard {
    p {
      font-size: 1.2rem;
    }
    .shopname {
      h3 {
        font-size: 1.6rem;
      }
      p {
        font-size: 1.2rem;
        span {
          font-size: 1.6rem;
        }
      }
    }
    .total {
      span {
        font-size: 1.6rem;
        color: $fill-blue-font;
        font-family: 'db_heaventmed';
      }
    }
  }
}
